<template>
	<div class='route_container bg-app'>      
        <!-- {{ configData }}  ++ -->
        <nav v-if="!isMobile()" class="navbar navbar-light py-0 col-12 d-flex bg-app">
            <div class="col-md-4 col-xs-4 pl-60"><img :src="`${apiDomain}/img/${configData?.leftImg}`" style="height: 64px;" /></div>
            <div class="col-md-4 col-xs-4 text-center"><img :src="`${apiDomain}/img/${configData?.midImg}`" style="height: 64px;"/></div>
            <div class="col-md-4 col-xs-4 text-right pr-60"><img :src="`${apiDomain}/img/${configData?.rightImg}`" style="height: 64px;"/></div>
        </nav>
        <nav v-else class="py-1 d-flex bg-app">
            <div class="col-md-4 col-xs-4"><img :src="`${apiDomain}/img/${configData?.leftImg}`" style="height: 48px;" /></div>
            <div class="col-md-4 col-xs-4 text-center"><img :src="`${apiDomain}/img/${configData?.midImg}`" style="height: 48px;"/></div>
            <div class="col-md-4 col-xs-4 text-right"><img :src="`${apiDomain}/img/${configData?.rightImg}`" style="height: 48px;"/></div>
        </nav>

        <div class="d-flex justify-content-center align-items-center">
            <div v-if="!isMobile()" class="p-0 bg-app" style="width: 5%; min-width: 196px; height: 100%;">
                <div class="d-flex align-items-center justify-content-center" style="height: 33.33vh;"><img :src="`${apiDomain}/img/${configData?.leftFirstImg}`" style="height: 80px;" /></div>
                <div class="d-flex align-items-center justify-content-center" style="height: 33.33vh;"><img :src="`${apiDomain}/img/${configData?.leftSecondImg}`" style="height: 80px;"/></div>
                <div class="d-flex align-items-center justify-content-center" style="height: 33.33vh;"><img :src="`${apiDomain}/img/${configData?.leftThirdImg}`" style="height: 80px;"/></div>
            </div>

            <div>
                <div class="row">
                    <div class="col-md-6 offset-md-3 mt-1">
                        <h3 v-if="isMobile()" class="text-center">Dein Code</h3>
                        <h1 v-else class="text-center">Dein Code</h1>
                    </div>

                    <div class="col-md-6 offset-md-3 mt-2 text-right" style="font-style: italic;">
                        <a v-if="isMobile()" class="text-info" href="https://m-texte.de" target="blank"><font-awesome-icon :icon="['fa', 'link']" class="mr-1" /> m-texte.de</a>
                        <a v-else class="text-info" href="https://m-texte.de" target="blank"><font-awesome-icon :icon="['fa', 'link']" class="mr-1" /> m-texte.de</a>
                    </div>

                    <!-- Phrase -->
                    <div class="col-md-6 offset-md-3 py-2">
                        <div class="text-center">
                            <h3 v-if="!isMobile()">Hinein</h3>
                            <h5 v-else>Hinein</h5>
                        </div>
                        <div :class="!isMobile() ? 'px-1' : ''">                    
                            <textarea v-model="textIn" class="col-md-12" rows="4" placeholder="You can type here ..." style="font-size: 14pt;"></textarea>
                        </div>

                        <!-- Btns -->
                        <div class="col-md-6 offset-md-3 pt-2 px-0">
                            <div class="d-flex justify-content-center">
                                <button class="btn btn-info w-100" @click="convert" :style="isMobile() ? 'font-size: 10pt' : 'font-size: 12pt'">
                                    Zu Unicode
                                </button>                        
                            </div>                
                        </div>
                    </div>

                    <!-- Translation -->
                    <div class="col-md-6 offset-md-3 pt-4">
                        <h3 v-if="!isMobile()" class="text-center">Es kommt hinaus</h3>
                        <h5 v-else class="text-center">Es kommt hinaus</h5>
                        <div :class="!isMobile() ? 'px-1' : ''">
                            <textarea v-on:focus="$event.target.select()" ref="clone" class="col-md-12" rows="4" readonly :value="textOut" style="font-size: 14pt;"></textarea>
                        </div>
                        
                        <!-- Imgs showing -->
                        <div class="row pt-2 px-0 d-flex align-items-center justify-content-center">
                            <div v-for="(txt, index) in textImgOut" :key="index">
                                <span v-if="!validURL(txt)">{{ txt }}</span>
                                <img v-else :src="txt" />
                            </div>
                        </div>

                        <!-- Btns -->
                        <div class="col-md-6 offset-md-3 pt-2 px-0">
                            <div class="d-flex justify-content-center">
                                <button class="btn btn-info w-100" @click="copy" :style="isMobile() ? 'font-size: 10pt' : 'font-size: 12pt'">
                                    Text kopieren
                                </button>                        
                            </div>                
                        </div>            
                    </div>

                    <!-- text -->
                    <div class="col-md-6 offset-md-3 py-4 mt-4">
                        <p class="bg-grey p-2 mx-1">
                            {{ configData?.description }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="!isMobile()" class="p-0 bg-app" style="width: 5%; min-width: 196px; height: 100%;">
                <div class="d-flex align-items-center justify-content-center" style="height: 33.33vh;"><img :src="`${apiDomain}/img/${configData?.rightFirstImg}`" style="height: 80px;"/></div>
                <div class="d-flex align-items-center justify-content-center" style="height: 33.33vh;"><img :src="`${apiDomain}/img/${configData?.rightSecondImg}`" style="height: 80px;"/></div>
                <div class="d-flex align-items-center justify-content-center" style="height: 33.33vh;"><img :src="`${apiDomain}/img/${configData?.rightThirdImg}`" style="height: 80px;"/></div>
            </div>
        </div>        

	</div>
</template>

<script>
import {apiDomain} from '@/config'
import Multiselect from 'vue-multiselect'
import axios from 'axios'

export default {        
    name: 'HomePageComponent',
    components: {
        apiDomain,
        Multiselect,
    },
    data () {
        return {
            apiDomain: apiDomain,            
            trends_lists: [],
            apiLoaded: true,
            rJson: {},
            textIn: '',
            textOut: '',
            textImgOut: [],
            configData: {
                description: `Omnis mourimur et quasi aqua dilabimur super terram. So, nothing is saved here, you come, you go. Nobody looking at your words.`,
                leftImg: 'left.png',
                leftFirstImg: 'left_first.png',
                leftSecondImg: 'left_second.png',
                leftThirdImg: 'left_third.png',
                midImg: 'mid.png',
                rightImg: 'right.png',
                rightFirstImg: 'right_first.png',
                rightSecondImg: 'right_second.png',
                rightThirdImg: 'right_third.png',
            }
        }
    },
    created() {
        this.getJson()
    },
    methods:{  
        getJson: function(){
            axios.get(`${this.apiDomain}/rule.json`)
                .then( response => (this.rJson = response.data ));            
        },
        copy: function(){
            this.$refs.clone.focus();
            document.execCommand('copy');
        },
        convert: function(){
            if(!this.textIn.length){
                this.textOut = '';
            }else{
                let xx = this;
                this.textOut = '';
                this.textImgOut = [];                
                [...this.textIn].forEach( (x) => {                    
                    if(!this.validURL(xx.rJson[x])){
                        xx.textOut += (xx.rJson[x] || x);
                    }
                    xx.textImgOut.push(xx.rJson[x] || x);
                } );
            }
        },
        validURL(str) {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }                  
    },
    computed: { 
        isAuth() {
            return this.$store.state.meta.auth && !!this.$store.state.meta.auth.name;
        },
        // configData(){
        //     debugger
        //     return window?.configData;
        // }              
    },
    watch: {
        $route: function(){
        },        
    } 
}
</script>
<style lang='scss' scoped>
	@import '../../assets/scss/elementStyles.scss';
	@import '../../assets/scss/idec.scss';	
    @import '../../assets/scss/vue-multiselect.min.css';
    .a-hover-white{
        color: black!important;
        @at-root #{&}:hover{
            color: white!important;
        }
    }
    .list-item:hover{
        background-color: rgba(9,37,64,.05);
    }
</style>
<style>
.trending-store-div{
    max-width: 850px!important;
}
@media (max-width: 420px) {
 
}
.w-25-5{
    width: 25.5%!important;
}
.my-xs{
    margin-top: 0.01rem!important;
    margin-bottom: 0.01rem!important;
}
.min-120{
    min-width: 120px!important;
}
.bg-app{
    background-color: #80808014;
}
.bg-grey{
    background-color: hsla(0,0%,50.2%,.21176470588235294);
}
.pl-60{
    padding-left: 60px!important;
}
.pr-60{
    padding-right: 60px!important;
}
</style>

