<template>
	<div class='route_container not_found'>
		<h1 class='not_found__title'>404 - page not found</h1>

		<div class='not_found__box'>
			<p class='not_found__box--large_text'>
				The page you are looking <strong>{{$route.fullPath}}</strong> has no page
			</p>
			<p>
				<a :href='`/${$store.state.meta.lang}/home`' class='button'>Click here to come back to main page.</a>
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'NotFound'
	}
</script>

<style lang='scss' scoped>
	@import '../../assets/scss/_variables.scss';
	@import '../../assets/scss/_elementStyles.scss';

	.not_found {
		@at-root #{&}__title {
			font-family: $font--role-emphasis;
			font-size: 4rem;
			margin-top: 4rem;
			padding: 2rem;
			text-align: center;
		}

		@at-root #{&}__box {
			background-color: #fff;
			border-radius: 0.25rem;
			padding: 2rem;
			color: #000;

			.button {
				margin-right: 0.5rem;
			}

			@at-root #{&}--large_text {
				font-size: 1.5rem;
			}
		}

	}

	@media (max-width: 525px) {
		.not_found {
			@at-root #{&}__title {
				font-size: 2.5rem;
			}

			@at-root #{&}__box {
				word-wrap: break-word;

				p:nth-child(2) {
					display: flex;
					flex-direction: column;
					align-items: center;

					span {
						margin: 0.5rem 0;
					}
				}
			}
		}
	}
</style>
