import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
	state: {
		meta: {
			lang: 'en',
			name: '',			
		},
		accountModal: false,
		username: '',
		email: true,
		points: 0,
		level: 0,
		role: '',		
		show404Page: false,
		ajaxErrors: [],
		ajaxErrorsModal: false,		
	},
	getters: {
		categoriesWithoutAll (state) {
			let categories =
				state.meta.categories
				.filter(category => ['ALL', 'OTHER'].indexOf(category.value) === -1 )

			categories.unshift({
				name: 'Select a category',
				disabled: true
			})

			return categories.filter(category => category.value !== 'ALL' )
		},		
		alphabetizedCategories (state) {
			return state.meta.categories.sort((a, b) => {
				if(a.name === 'All') return -1
				if(a.name === 'Other' || b.name === 'Other') return -1

				if(a.name < b.name) {
					return -1
				} else if (a.name > b.name) {
					return 1
				}

				return 0
			})
    	},
		alphabetizedGames (state) {
				return state.meta.games.sort((a, b) => {
					if(a.name < b.name) {
						return -1
					} else if (a.name > b.name) {
						return 1
					}

					return 0
				})
		},
		isGameExist (state) {
			return function(gameseo){
				return state.meta.gamesDict[gameseo] || false
			}
		},
		authenticated (state) {
			return !!state.meta.auth.username
		},	
		getTargetDomain(state){
			return Object.keys(state.meta.targetDomain) ? state.meta.targetDomain : false;
		},
		canManageUser (state) {
			return state.role == 'Super'
		},
		canViewReport (state) {
			return state.role == 'Super' || state.role == 'Admin'
		},
		canManageSetting (state) {
			return state.role == 'Super' || state.role == 'Admin'
		},
		canManageThread (state) {
			return state.role == 'Super' || state.role == 'Admin' || state.role == 'Moderator'
		},		
	},
	actions: {		
		setTitle ({ state, getters }, value) {
			console.log(state,getters,value)
		},
		checkGameValidity({ state }, value) {
			if(state.meta.games.length > 0 && value && !state.meta.gamesDict[value])
				this.commit('set404Page', true)
		}		
	},
	mutations: {
		setLang (state, lang) {
			state.meta.lang = lang
		},		
		setAuth(state, value){
			state.meta.auth = value
		},
		set404Page (state, value) {
			state.show404Page = value
		},	
		setTargetDomain(state, value){
			state.meta.targetDomain = value
		},
		setSelectOptions (state, payload) {
			state.selectOptions[payload.name] = payload.value;
		},
		setAccountModalState (state, value) {
			state.accountModal == value;
		},
		setAjaxErrorsModalState (state, value) {
			state.ajaxErrorsModal = value;
		},
		setAjaxErrors (state, value) {
			state.ajaxErrors = value
		},
		setProfileName (state, value) {
			state.meta.auth.profile_name = value
		},
		setProfileRName (state, value) {
			state.meta.auth.profile_rname = value
		},
		setDescription (state, value) {
			state.meta.auth.description = value
		},
		setAvatar (state, value) {
			state.meta.auth.avatar = value
		},
		setAvatarFrames (state, value) {
			state.meta.auth.avatarframes = value
		},
		setMini(state, value) {
			state.meta.auth.background = value
		},

		setEmail (state, value) {
			state.email = value
		},
		setPoints (state, value) {
			state.points = value
		},
		setLevel (state, value) {
			state.level = value
		},		
		setRole (state, value) {
			state.role = value
		},					
		setSettings (state, value) {
			state.meta.name = value.forumName || ''
			state.meta.description = value.forumDescription || ''
			state.meta.showDescription = value.showDescription || false
		},
		addCategories (state, value) {
			if(Array.isArray(value)) {
				state.meta.categories = [
					{ name: 'All', value: 'ALL', color: '#1565C0' },
					...value
				]
			} else {
				state.meta.categories.push(value)
			}
		},
		removeCategory (state, id) {
			let category = state.meta.categories.filter(c => c.id === id)
			let index = state.meta.categories.indexOf(category)

			state.meta.categories.splice(index, 1)
		},
		updateCategory (state, updated) {
			let category = state.meta.categories.filter(c => c.id === updated.id)
			let index = state.meta.categories.indexOf(category)

			state.meta.categories.splice(index, 1, updated)
		},

		addGames (state, value) {
			if(Array.isArray(value)) {
				state.meta.gamesDict = {}
				state.meta.games = value
				value.map(g => g.id && (state.meta.gamesDict[g.seoname]=g))
			} else {
				state.meta.games.push(value)
				value.id && (state.meta.gamesDict[value.seoname]=value)
			}
		},

		setConnectionInfo(state, value){
			state.meta.connection_info = value;
		},
	},
})
