<template>
	<div id='app'>
		<div v-if="!pgLoaded" class='gamer-container-gray mx-auto vh-100 pt-4 mt-4 px-4'>            
            <thread-display-placeholder></thread-display-placeholder>
        </div>
		<div v-else>
			<not-found v-show='$store.state.show404Page'></not-found>
			<transition name='fade'>
				<router-view v-show='!$store.state.show404Page'></router-view>
			</transition>
		</div>
	</div>
</template>
<script>
	import { defineAsyncComponent } from 'vue'
	import NotFound from './components/routes/NotFound.vue'

	export default {
    name: 'app',
		components: {
			defineAsyncComponent,
			NotFound
		},
		data () {
			return {
				pgLoaded: false,
				loadingLogout: false,
			}
		},
		created(){	
			// App.vue
			this.pgLoaded = true;	
			
		},	
		mounted(){
		},
		methods: {	
					
		},
		computed: {
			showAjaxErrorsModal: {
				get () { return this.$store.state.ajaxErrorsModal },
				set (val) { this.$store.commit('setAjaxErrorsModalState', val) }
			},			
		},		
	}
</script>
<style lang='scss'>
	@font-face {
		font-family: 'SVN-Gilroy', sans-serif;
		src: url("./assets/scss/fonts/Gilroy-ExtraBold.otf") format("truetype");
	}
	@import './assets/scss/elementStyles.scss';
	@import './assets/scss/nprogress.scss';
	@import './assets/scss/idec.scss';	
</style>